import scroll from "infinite-scroll";

window.InfiniteScroll = scroll;

import Isotope from "isotope-layout";
import imagesLoaded from "imagesloaded";
import Swiper from "swiper/bundle";
import axios from "axios";
import "lazysizes/plugins/parent-fit/ls.parent-fit";
import "lazysizes";
window.axios = axios;

// import "./components/toggle";

InfiniteScroll.imagesLoaded = imagesLoaded;

document.addEventListener("DOMContentLoaded", function () {
    document.addEventListener("lazyloaded", function (e) {
        if (typeof grid !== "undefined") {
            grid.layout();
        }
    });

    // Check if any elements with the class 'js-grid-projects' exist
    if (document.querySelector(".js-grid-projects")) {
        // Select the first element with the class 'js-grid-projects'
        var gridElement = document.querySelector(".js-grid-projects");

        // Initialize Isotope
        var grid = new Isotope(gridElement, {
            itemSelector: ".grid-item",
            percentPosition: true,
            masonry: {
                columnWidth: ".grid-sizer",
            },
        });

        // Perform the layout
        grid.layout();
    }

    // Check if any elements with the class 'js-grid-layout' exist
    if (document.querySelector(".js-grid-layout")) {
        // Initialize Isotope
        var grid = new Isotope(".js-grid-layout", {
            itemSelector: ".grid-item",
            percentPosition: true,
            layoutMode: "masonry",
        });

        // Get all filter items
        var filterItems = document.querySelectorAll(
            "#tutorials-filter .filter-item"
        );

        // Add click event listener to each filter item
        filterItems.forEach(function (item) {
            item.addEventListener("click", function (e) {
                e.preventDefault();

                // Get the filter value from the clicked item
                var filterValue = item.getAttribute("data-filter");
                grid.arrange({ filter: filterValue });

                // Remove 'active' class from all filter items
                filterItems.forEach(function (filterItem) {
                    filterItem.classList.remove("active");
                });

                // Add 'active' class to the clicked item
                item.classList.add("active");
            });
        });
    }

    // Check if any elements with the class 'js-grid-infinite-scroll' and '.page-link[rel="next"]' exist
    if (
        document.querySelector(".js-grid-infinite-scroll") &&
        document.querySelector('.page-link[rel="next"]')
    ) {
        new InfiniteScroll(".js-grid-infinite-scroll", {
            path: '.page-link[rel="next"]',
            append: ".grid-item",
            history: false,
            bufferPx: 2000,
            prefill: true,
            outlayer: grid,
            loading: {
                finishedMsg: "No more items to load.",
                img: "//i.imgur.com/qkKy8.gif",
            },
        });
    }

    var Slider = new Swiper("#slider", {
        loop: true,
        effect: "fade",
        autoplay: {
            delay: 5000,
        },
    });

    // Initialize the product gallery thumbs Swiper
    var productGalleryThumbs = new Swiper(".js-product-slider-thumbs", {
        spaceBetween: 10,
        slidesPerView: 4,
        // freeMode: true,
        threshold: 5,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
    });

    // Initialize the product gallery Swiper
    var productGallery = new Swiper(".js-product-slider-gallery", {
        loop: true,
        autoHeight: true,
        threshold: 10,
        spaceBetween: 10,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        autoplay: {
            delay: 4000,
        },
        thumbs: {
            swiper: productGalleryThumbs,
        },
        on: {
            init: function () {
                // Removes blur effect on duplicated slides
                var duplicatedSlides = document.querySelectorAll(
                    ".swiper-slide-duplicate img"
                );
                duplicatedSlides.forEach(function (img) {
                    img.classList.remove("blurred-loading");
                });

                // Set the opacity of the thumbnails
                var thumbsElement = document.querySelector(
                    ".js-product-slider-thumbs"
                );
                if (thumbsElement) {
                    thumbsElement.style.opacity = 1;
                }
            },
        },
    });

    // Initialize the home slider Swiper
    var homepageSwiper = new Swiper(".js-carousel-slider", {
        spaceBetween: 0,
        loop: true,
        slidesPerView: 1,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        autoplay: {
            delay: 4000,
        },
        breakpoints: {
            640: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
        },
    });

    new Swiper(".js-testimonials-slider", {
        spaceBetween: 100,
        loop: true,
        slidesPerView: 1,
        centeredSlides: true,
        grabCursor: true,

        autoplay: {
            delay: 3000,
            pauseOnMouseEnter: true,
            disableOnInteraction: true,
        },
        breakpoints: {
            900: {
                slidesPerView: 1.5,
            },
            1500: {
                slidesPerView: 2.6,
            },
            2000: {
                slidesPerView: 3,
            },
        },
    });

    new Swiper(".js-testimonials-slider-2", {
        spaceBetween: 100,
        loop: true,
        slidesPerView: 1,
        centeredSlides: true,
        grabCursor: true,

        effect: "coverflow",
        coverflowEffect: {
            rotate: 50,
            stretch: 0,
            depth: 50,
            modifier: 1.1,
            slideShadows: false,
        },

        autoplay: {
            delay: 3000,
            pauseOnMouseEnter: true,
            disableOnInteraction: true,
        },
        breakpoints: {
            900: {
                slidesPerView: 1.5,
            },
            1500: {
                slidesPerView: 2.6,
            },
            2000: {
                slidesPerView: 3,
            },
        },
    });

    window.onscroll = function () {
        const halfScreenHeight = window.innerHeight / 2;
        const element = document.querySelector(".wp-nav-js");
        if (!element) return;

        const classList = element.classList;

        if (window.scrollY > halfScreenHeight) {
            // The user has scrolled past 50% of the screen height
            classList.add("wp-nav-fixed");

            // wait for the transition to finish
            setTimeout(() => {
                classList.add("wp-nav-moving");
            }, 1);

            // change css top property to 0
            // document.querySelector(".wp-nav-fixed").classList.add("top-0");
        } else {
            // The user is above 50% of the screen height
            classList.remove("wp-nav-fixed", "wp-nav-moving");
        }
    };
});
